import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { PreloaderService, SettingsService } from '@core/bootstrap';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { WINDOW } from '@core/injection-tokens';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private _subscription = new Subscription();
  private _bodyElement!: HTMLBodyElement;

  constructor(
    private readonly _preloader: PreloaderService,
    private readonly _settings: SettingsService,
    private readonly _router: Router,
    private readonly _renderer: Renderer2,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: Window
  ) {
    this._bodyElement = this._document.querySelector('body')!;
  }

  ngOnInit() {
    this._settings.updateScheme();
    this._settings.updateTheme();
    this._appendPreconnectLink();
    this._handleNonManagementRoutesTheme();
    initFlowbite();
  }

  ngAfterViewInit(): void {
    this._preloader.hide();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _handleNonManagementRoutesTheme(): void {
    this._subscription.add(
      this._router.events.subscribe(routerEvent => {
        if (routerEvent instanceof NavigationStart) {
          if (!routerEvent.url.startsWith('/manage')) {
            this._bodyElement.classList.remove('light', 'dark');
            this._bodyElement.classList.add('light');
          } else {
            this._settings.updateScheme();
          }
          this._settings.updateTheme();
        }
      })
    );
  }

  private _appendPreconnectLink(): void {
    const preconnectHost =
      environment.STAGE === 'local'
        ? this._window.location.origin
        : new URL(environment.ASSET_URL).origin;

    const link: HTMLLinkElement = this._renderer.createElement(
      'link'
    ) as HTMLLinkElement;
    link.rel = 'preconnect';
    link.href = preconnectHost;
    this._renderer.appendChild(this._document.head, link);
  }
}
