import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { routes } from './app.routes';
import { provideAuth0 } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { appInitializerProviders } from '@core/initializers';
import { httpInterceptorProviders } from '@core/interceptor';
import { globalErrorHandlerProviders } from '@core/handler';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatDialogModule } from '@angular/material/dialog';
import { graphqlProviders } from '@graphql/graphql-api.config';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import {
  IMAGE_LOADER,
  ImageLoaderConfig,
  registerLocaleData,
} from '@angular/common';
import { WINDOW } from '@core/injection-tokens';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18nService } from '@shared/services';
import localDe from '@angular/common/locales/de';
import localDeExtra from '@angular/common/locales/extra/de';
import 'moment/locale/de';

/* Register de locale */
registerLocaleData(localDe, 'de-DE', localDeExtra);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withComponentInputBinding()
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAuth0({
      domain: environment.AUTH0_DOMAIN,
      clientId: environment.AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.AUTH0_GRAPHQL_AUDIENCE,
      },
      errorPath: 'error/auth',
      httpInterceptor: {
        allowedList: [
          {
            uri: environment.HTTP_GRAPHQL_ENDPOINT,
            allowAnonymous: true,
          },
        ],
      },
    }),
    importProvidersFrom(
      MatDialogModule,
      NgProgressRouterModule,
      NgxPermissionsModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) =>
            new TranslateHttpLoader(
              http,
              `${environment.ASSET_URL}/i18n/`,
              '.json'
            ),
          deps: [HttpClient],
        },
      })
    ),
    {
      provide: MatPaginatorIntl,
      useFactory: (paginatorI18nService: PaginatorI18nService) =>
        paginatorI18nService.getPaginatorIntl(),
      deps: [PaginatorI18nService],
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return `${environment.ASSET_URL}/img/${config.src}`;
      },
    },
    { provide: WINDOW, useValue: window },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        subscriptSizing: 'dynamic',
      },
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: () => navigator.language,
    },
    provideMomentDateAdapter({
      parse: {
        dateInput: 'L',
      },
      display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }),
    graphqlProviders,
    appInitializerProviders,
    httpInterceptorProviders,
    globalErrorHandlerProviders,
  ],
};
