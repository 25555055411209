/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
import { Provider } from '@angular/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink, HttpLinkHandler } from 'apollo-angular/http';
import { environment } from '@env/environment';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  StoreObject,
} from '@apollo/client/core';
import { AuthService } from '@auth0/auth0-angular';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { lastValueFrom } from 'rxjs';
import { Kind, OperationTypeNode } from 'graphql/language';
import { ErrorDialogService } from '@shared/services';
import { onError } from '@apollo/client/link/error';

import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

const generateUniqueId = (object: Readonly<StoreObject>): string => {
  return (
    (object['cacheKey'] ?? object['id'])
      ? `${object.__typename}:${object['id'] as string}`
      : null
  ) as string;
};

const createHttpLink = (httpLink: HttpLink): HttpLinkHandler => {
  return httpLink.create({
    uri: environment.HTTP_GRAPHQL_ENDPOINT,
    // @ts-ignore
    extractFiles: body => extractFiles(body, isExtractableFile),
  });
};

const createWsLink = (authService: AuthService): GraphQLWsLink => {
  return new GraphQLWsLink(
    createClient({
      url: environment.WS_GRAPHQL_ENDPOINT,
      lazy: true,
      connectionParams: async () => {
        const authToken = await lastValueFrom(
          authService.getAccessTokenSilently()
        );
        return { authToken };
      },
    })
  );
};

const createErrorLink = (errorDialogService: ErrorDialogService) => {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map((error: any) => {
        console.error(`[GraphQL error]: ${error.message}`);
        errorDialogService.openDialog({
          type: 'GraphQL',
          error,
          location: window.location.href,
        });
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError.message}`);
      errorDialogService.openDialog({
        type: 'Network',
        error: networkError,
        location: window.location.href,
      });
    }
  });
};

const createApolloLinks = (
  httpLink: HttpLink,
  authService: AuthService,
  errorDialogService: ErrorDialogService
): ApolloClientOptions<NormalizedCacheObject> => {
  const ws = createWsLink(authService);
  const http = createHttpLink(httpLink);
  const error = createErrorLink(errorDialogService);

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === Kind.OPERATION_DEFINITION &&
        definition.operation === OperationTypeNode.SUBSCRIPTION
      );
    },
    ws,
    http
  );

  return {
    link: error.concat(link),
    cache: new InMemoryCache({
      dataIdFromObject: generateUniqueId,
    }),
  };
};

export const graphqlProviders: Provider[] = [
  {
    provide: APOLLO_OPTIONS,
    useFactory: createApolloLinks,
    deps: [HttpLink, AuthService, ErrorDialogService],
  },
  Apollo,
];
